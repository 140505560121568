import { frontendURL } from '../../../helper/URLHelper';
const SettingsContent = () => import('../settings/Wrapper.vue');
const DashboardAppFrames = () => import('./DashboardAppFrames.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/:mainSlugName/:mainSlugId'),
      roles: ['administrator', 'agent'],
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'dashboard_app_frames';
        const backUrl = { name: 'dashboard_app_frames' };
        return {
          showBackButton,
          showSidemenuIcon: false,
          backUrl,
        };
      },
      children: [
        {
          path: '',
          name: 'dashboard_app_frames',
          component: DashboardAppFrames,
          roles: ['administrator'],
          props: route => {
            return { mainSlugId: route.params.mainSlugId };
          },
        },
      ],
    },
    {
      path: frontendURL(
        'accounts/:accountId/:mainSlugName/:mainSlugId/:subSlugName/:subSlugId'
      ),
      roles: ['administrator', 'agent'],
      component: SettingsContent,
      props: params => {
        const showBackButton =
          params.name !== 'dashboard_app_frames_for_sub_slug';
        const backUrl = { name: 'dashboard_app_frames_for_sub_slug' };
        return {
          showBackButton,
          backUrl,
        };
      },
      children: [
        {
          path: '',
          name: 'dashboard_app_frames_for_sub_slug',
          component: DashboardAppFrames,
          roles: ['administrator'],
          props: route => {
            return {
              mainSlugId: route.params.mainSlugId,
              subSlugId: route.params.subSlugId,
            };
          },
        },
      ],
    },
  ],
};
