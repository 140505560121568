// store/modules/mainSlugs.js

import axios from 'axios';
import * as types from '../mutation-types';

const state = {
  mainSlugs: [],
};

const getters = {
  allMainSlugs($state) {
    return $state.mainSlugs;
  },
};

const actions = {
  async fetchMainSlugs({ commit }, { currentAccountId }) {
    try {
      const response = await axios.get(
        `/main_slugs?account_id=${currentAccountId}`
      );
      const data = response.data;
      commit(types.default.SET_MAIN_SLUGS, data);
    } catch (error) {
      // eslint-disable-next-line
      console.error('Error fetching main slug:', error);
    }
  },
};

const mutations = {
  [types.default.SET_MAIN_SLUGS]($state, data) {
    $state.mainSlugs = data;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
