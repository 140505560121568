import { frontendURL } from '../../../../helper/URLHelper';

const dashboardApps = accountId => ({
  parentNav: 'dashboardApps',
  routes: [
    'dashboard_apps',
    'dashboard_app_frames',
    'dashboard_app_frames_for_sub_slug',
    'settings_integrations_dashboard_apps',
  ],
  menuItems: [
    {
      icon: 'contact-card-group',
      label: 'DASHBOARD_APPS',
      toState: frontendURL(`accounts/${accountId}/dashboard-apps`),
      toStateName: 'dashboard_apps',
    },
  ],
});
export default dashboardApps;
